import React from 'react';
import { navigate, graphql } from 'gatsby';
import queryString from 'query-string';
import ToolboxLayout from 'layouts/toolboxLayout';
import LRCResultsHero from 'components/Tools/LaborRateCalculator/ResultsHero';
import ROIResultHow from 'components/Tools/ROICalculator/ResultHow';
import DemoFormSection from 'components/Sections/DemoFormSection';
import { convertParamsToToolResult } from '../../../utils/urlHelper';
import SocialSharing from 'components/SocialSharing';
import { createToolsBreadcrumbs } from '../utils/createToolsBreadcrumbs';

const LaborRateCalcResultPage = (props) => {
  const { pagePrefix } = props.pageContext;
  const { cardsBlock, seo } = props.data.contentfulTool;
  const breadcrumbs = createToolsBreadcrumbs('Labor Rate Calculator Results', true);
  const url = props.location.search;
  const params = queryString.parse(url);
  let results = {
    overhead: null,
    techCount: null,
    holidaysPerTech: null,
    vacationDaysPerTech: null,
    billableHourEfficiency: null,
    hourlyRatePerTech: null,
    netProfit: null,
    projectedBillableHoursPerTech: null,
    coverOverheadOnly: null,
    breakEvenRate: null,
    billableLaborRate: null,
  };

  results = convertParamsToToolResult(results, params);

  const incompleteParams = Object.values(results).filter((elem) => elem === null);

  if (typeof window !== 'undefined' && incompleteParams.length > 0) {
    navigate(`/${pagePrefix}`);
    return null;
  }

  return (
    <ToolboxLayout
      breadcrumbs={breadcrumbs}
      contentfulSeo={{
        ...seo,
        pageUrl: `${pagePrefix}-results`,
        pageTitle: 'Labor Rate Calculator Result | ServiceTitan',
      }}
      layout="toolbox"
      {...props}
    >
      <LRCResultsHero results={results} />
      <ROIResultHow
        background="var(--skyline-1)"
        title="How can ServiceTitan increase revenue?"
        cards={cardsBlock?.cards}
      />
      <DemoFormSection
        backgroundColor="var(--dark)"
        formTitle="Let’s get started."
        description="Explore why 100,000+ contractors trust Service Titan to run their business."
        title="Book a personalized demo today."
      />
    </ToolboxLayout>
  );
};

export default LaborRateCalcResultPage;

export const LaborRateCalcResultPageQuery = graphql`
  query LaborRateCalcResultPageQuery($internalName: String!) {
    contentfulTool(internalName: { eq: $internalName }) {
      ...Tool
    }
  }
`;
